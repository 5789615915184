import React, { Component, useCallback, useRef, forwardRef } from 'react';

import('./form-field.scss');

const FormField = forwardRef((props, ref) => {
  return (
    <div className="login-form-field">
      <label className="login-form-field-label">{props.label}</label>
      <input
        ref={ref}
        type={props.type || 'text'}
        className="login-form-field-input"
        name={props.name}
      />
    </div>
  );
});

export default FormField;
