export const getTrainingsTime = (weeklyTrainings, currentTraining) => {
  let trainings = weeklyTrainings;

  if (currentTraining) {
    const training = { ...currentTraining };
    training.endTime = new Date();
    trainings = trainings.concat(training);
  }

  const trainingTimeMs = trainings.reduce((acc, training) => {
    const trainingTimeMs =
      new Date(training.endTime).getTime() -
      new Date(training.startTime).getTime();

    acc = acc + trainingTimeMs;

    return acc;
  }, 0);

  const trainingTimeMintes = Math.round(trainingTimeMs / (60 * 1000));
  const hours = Math.floor(trainingTimeMintes / 60);
  const minutes = trainingTimeMintes - hours * 60;

  return {
    hours,
    minutes,
    trainingTimeMintes,
    trainingTimeMs
  };
};

export const hoursAndMinutesToText = (hours, minutes) => {
  let text;
  if (hours !== 0 && minutes !== 0) {
    text = `${hours}&nbsp;שעות&nbsp;ו&nbsp;${minutes}&nbsp;דקות`;
  } else if (hours !== 0) {
    text = `${hours}&nbsp;שעות`;
  } else {
    text = `${minutes}&nbsp;דקות`;
  }
  return text;
};

export const getTrainingTimeGoalText = (
  weeklyTrainings,
  currentTraining,
  goalHours
) => {
  const { trainingTimeMs } = getTrainingsTime(weeklyTrainings, currentTraining);
  const timeLeftMs = goalHours * 60 * 60 * 1000 - trainingTimeMs;
  const timeLeftMinutes = Math.round(timeLeftMs / (60 * 1000));
  if (timeLeftMinutes <= 0) {
    return 'כל הכבוד! היעד השבועי הושלם בהצלחה!';
  }
  const hours = Math.floor(timeLeftMinutes / 60);
  const minutes = timeLeftMinutes - hours * 60;
  const text = `זמן נותר להשלמת היעד השבועי: ${hoursAndMinutesToText(
    hours,
    minutes
  )}`;
  return text;
};

export const getWeeklyTrainingsTimeText = (
  weeklyTrainings,
  currentTraining
) => {
  const { hours, minutes } = getTrainingsTime(weeklyTrainings, currentTraining);
  const text = `זמן אימונים מצטבר מתחילת השבוע: ${hoursAndMinutesToText(
    hours,
    minutes
  )}`;
  return text;
};

export const getTrainingEndTitle = training => {
  const { hours, minutes } = getTrainingsTime([training]);
  const totalMinutes = minutes + hours * 60;
  let text;
  if (totalMinutes > 4) {
    text = 'כל&nbsp;הכבוד! האימון הסתיים!';
    text = text + '<br />' + 'זמן האימון: ' + getTrainingTimeText(training);
  } else {
    text = `הסתיים האימון, אבל למה רק ${totalMinutes} דקות?`;
  }
  return text;
};

export const getTrainingTimeText = training => {
  const { hours, minutes } = getTrainingsTime([training]);
  return hoursAndMinutesToText(hours, minutes);
};

export const getWeeklyCaloriesBurnedText = (
  weeklyTrainings,
  currentTraining,
  caloriesPerHour
) => {
  const { trainingTimeMs } = getTrainingsTime(weeklyTrainings, currentTraining);
  const caloriesBurned = Math.round(
    (trainingTimeMs / (60 * 60 * 1000)) * parseFloat(caloriesPerHour)
  );
  const text = `שריפת אנרגיה מתחילת השבוע: ${caloriesBurned}&nbsp;קלוריות`;
  return text;
};
