const isLocalHost = window.location.toString().includes('localhost');
const isProd = process.env.NODE_ENV === 'production';

var API_URL;
var AUTH_API_URL;

if (isProd && isLocalHost) {
  AUTH_API_URL = 'http://localhost:8001/authapi';
  API_URL = 'http://localhost:8001/api';
} else if (isProd) {
  AUTH_API_URL = 'https://halichoni.ruthdoron.com/authapi';
  API_URL = 'https://halichoni.ruthdoron.com/api';
} else {
  AUTH_API_URL = 'http://localhost:8001/authapi';
  API_URL = 'http://localhost:8001/api';
}

const getApiUrl = () => {
  return API_URL;
};

const getAuthApiUrl = () => {
  return AUTH_API_URL;
};

const apiRequest = async (method, url, params) => {
  try {
    const requestOptions = {
      credentials: 'include',
      method: method
    };
    if (params) {
      requestOptions.body = JSON.stringify(params);
    }
    const resp = await fetch(`${API_URL}/${url}`, requestOptions);
    if (resp.status == 401) {
      console.log('shit 401 status code');
      return (window.location = '/login');
    }
    const jsonResponse = await resp.json();
    return jsonResponse;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const trainings = {
  startTraining: async () => {
    return await apiRequest('POST', 'trainings/start-training');
  },
  finishTraining: async () => {
    return await apiRequest('POST', 'trainings/finish-training');
  },
  getCurrentTraining: async () => {
    return await apiRequest('GET', 'trainings/get-current-training');
  },
  getWeeklyTrainings: async () => {
    return await apiRequest('GET', 'trainings/get-weekly-trainings');
  }
};

const users = {
  getCurrentUser: async () => {
    return await apiRequest('GET', 'users/get-current-user');
  }
};

const apiService = {
  trainings,
  users,
  getApiUrl,
  getAuthApiUrl
};

export default apiService;
