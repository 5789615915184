import React, { Component, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import FormField from './../form-field/form-field';
import apiService from './../../services/api-service';

import './signup.scss';

export default function LogIn(props) {
  const history = useHistory();

  const firstNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordVerificationRef = useRef();
  const weightRef = useRef();
  const caloriesToHourRef = useRef();
  const weightWLossWeeklyGoalRef = useRef();
  const trainingTimeHoursWeeklyGoalRef = useRef();

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
      console.log('hanlde submit');

      const firstName = firstNameRef.current.value;
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
      const passwordVerification = passwordVerificationRef.current.value;
      const weight = parseFloat(weightRef.current.value);
      const weightWLossWeeklyGoal = parseFloat(
        weightWLossWeeklyGoalRef.current.value
      );
      const caloriesToHour = parseInt(caloriesToHourRef.current.value);
      const trainingTimeHoursWeeklyGoal =
        trainingTimeHoursWeeklyGoalRef.current.value;

      if (!firstName) return alert('חובה למלא שם פרטי');
      if (!email || !email.toString().includes('@'))
        return alert('אימייל לא תקין');
      if (!password || password != passwordVerification) {
        return alert('סיסמה ואימות סיסמה לא שוות');
      }
      if (password.toString().length < 6) {
        return alert('סיסמה לא מספיק חזקה, בבקשה החלף לסיסמה יותר חזקה');
      }
      console.log(weightWLossWeeklyGoal);
      console.log(weight);
      if (
        isNaN(weightWLossWeeklyGoal) ||
        weightWLossWeeklyGoal < 0.01 ||
        weightWLossWeeklyGoal > 10
      ) {
        return alert(
          'יעד משקל שבועילא הגיוני או לא תקין, בבקשה למלא 0.05 או 1 או משהו הגיוני'
        );
      }
      if (isNaN(weight) || weight < 1 || weight > 150) {
        return alert('המשקל שהוזן לא תקין');
      }
      if (
        isNaN(caloriesToHour) ||
        caloriesToHour < 20 ||
        caloriesToHour > 2000
      ) {
        return alert(
          'קלוריות לשעה לא תקין, בבקשה הזן מספר הגיוני כמו 100 או 600'
        );
      }
      if (
        isNaN(trainingTimeHoursWeeklyGoal) ||
        trainingTimeHoursWeeklyGoal < 0.1 ||
        trainingTimeHoursWeeklyGoal > 100
      ) {
        return alert(
          'יעד זמן אימון בשבוע לא הגיוני, בבקשה הזן מספר הגיוני של שעות'
        );
      }

      try {
        async function signup() {
          const resp = await fetch(`${apiService.getAuthApiUrl()}/signup`, {
            credentials: 'include',
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              firstName,
              email,
              password,
              weight,
              caloriesToHour,
              weightWLossWeeklyGoal,
              trainingTimeHoursWeeklyGoal
            })
          });

          if (resp.status == 201) {
            history.push('/');
          } else {
            alert('ההרשמה נכשלה, מצטערים.');
          }
        }
        signup();
      } catch (e) {
        console.log('error');
        console.log('error', e);
      }
    }
  };

  const handleLoginClick = useCallback(() => {
    return history.push('/login');
  }, []);

  return (
    <div className="login-component-wrapper">
      <div className="login-component">
        <form onSubmit={handleSubmit}>
          <FormField name="firstName" label="שם פרטי" ref={firstNameRef} />
          <FormField name="email" label="אימייל" ref={emailRef} />
          <FormField
            name="password"
            type="password"
            label="סיסמה"
            ref={passwordRef}
          />
          <FormField
            name="passwordVerification"
            type="password"
            label="אימות סיסמה"
            ref={passwordVerificationRef}
          />
          <FormField name="weight" ref={weightRef} label='משקל (ק"ג)' />
          <FormField
            ref={weightWLossWeeklyGoalRef}
            name="weightWLossWeeklyGoal"
            label='יעד ירידת משקל בשבוע (ק"ג)'
          />

          <FormField
            ref={caloriesToHourRef}
            name="caloriesToHour"
            label="מספר קלוריות לשעת ספורט"
          />

          <FormField
            ref={trainingTimeHoursWeeklyGoalRef}
            name="trainingTimeHoursWeeklyGoal"
            label="יעד זמן אימון כולל שבועי (מספר שעות)"
          />

          <button type="submit" className="login-form-submit-button">
            הרשם
          </button>
        </form>
      </div>

      <div onClick={handleLoginClick} className="link-to-login">
        או <a href="#">הכנס כמשתמש קיים </a>
      </div>
    </div>
  );
}
