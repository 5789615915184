import React, { Component, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import apiService from './../../services/api-service';

import './login.scss';

export default function LogIn(props) {
  const history = useHistory();

  const emailRef = useRef();
  const passwordRef = useRef();

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
      console.log('hanlde submit');
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
      if (!email || !password) {
        return alert('חובה להזין אימייל וסיסמה');
      }

      try {
        async function login() {
          const resp = await fetch(`${apiService.getAuthApiUrl()}/login`, {
            credentials: 'include',
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email, password })
          });

          if (resp.status == 201) {
            history.push('/');
          } else {
            console.log('error');
            alert('שגיאה, יכול להיות שהאימייל או הסיסמה לא נכונים');
          }
        }
        login();
      } catch (e) {
        console.log('error', e);
      }
    }
  };

  const handleSignupClick = useCallback(() => {
    return history.push('/signup');
  }, []);

  return (
    <div className="login-component-wrapper">
      <div className="login-component">
        <form onSubmit={handleSubmit}>
          <div className="login-form-field">
            <label className="login-form-field-label">אימייל</label>
            <input
              ref={emailRef}
              type="text"
              className="login-form-field-input"
              name="email"
            />
          </div>
          <div className="login-form-field">
            <label className="login-form-field-label">סיסמה</label>
            <input
              ref={passwordRef}
              className="login-form-field-input"
              type="password"
              name="password"
            />
          </div>
          <button type="submit" className="login-form-submit-button">
            הכנס
          </button>
        </form>
      </div>
      <div className="link-to-sign-up">
        או{' '}
        <a onClick={handleSignupClick} href="#">
          הרשם כמשתמש חדש{' '}
        </a>
      </div>
    </div>
  );
}
