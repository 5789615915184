import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import apiService from './../../services/api-service';

export default function useGetCurrentUser() {
  const [user, setUser] = useState(null);
  const history = useHistory();
  useEffect(() => {
    try {
      async function getUser() {
        const resp = await fetch(
          `${apiService.getApiUrl()}/users/get-current-user`,
          {
            credentials: 'include'
          }
        );

        if (resp.status == 401) {
          return history.push('/login');
        }
        if (resp.status == 200) {
          const jsonResp = await resp.json();
          jsonResp.user && setUser(jsonResp.user);
        }
      }
      getUser();
    } catch (e) {
      console.error(e);
    }
  }, []);

  return user;
}
