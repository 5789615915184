import React from 'react';
import ReactDOM from 'react-dom';

import AppRouter from './components/router';
// import registerServiceWorker from './registerServiceWorker';

import './index.scss';

ReactDOM.render(<AppRouter />, document.getElementById('root'));

// registerServiceWorker();
