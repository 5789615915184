import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

import apiService from '../../services/api-service';
import useGetCurrentUser from './use-get-current-user';

import './main.scss';

import {
  getTrainingTimeText,
  getWeeklyTrainingsTimeText,
  getWeeklyCaloriesBurnedText,
  getTrainingTimeGoalText,
  getTrainingEndTitle
} from './trainings-time-service';

const HIDE_MESSAGES_TIME_MS = 30000;

export default function Main() {
  const [_forceUpdateVersion, setUpdateVersion] = useState(0);
  const user = useGetCurrentUser();

  const trainingClockRef = useRef();
  const weeklyTimeSentenceRef = useRef();
  const weeklyTimeGoalRef = useRef();
  const weeklyCaloriesBurned = useRef();

  const [trainingData, setTrainingData] = useState(null);
  const [trainingEndData, setTrainingEndData] = useState(null);
  useEffect(() => {
    if (!user) return;

    const getCurrentTraining = async () => {
      const res = await apiService.trainings.getCurrentTraining();
      if (res.training && res.training.startTime) {
        setTrainingData(res.training);
      }
    };
    getCurrentTraining();
  }, [user]);

  const [weeklyTrainings, setWeeklyTrainings] = useState(null);
  useEffect(() => {
    if (!user) return;
    const getWeeklyTrainings = async () => {
      const res = await apiService.trainings.getWeeklyTrainings();
      if (res.weeklyTrainings) setWeeklyTrainings(res.weeklyTrainings);
    };
    getWeeklyTrainings();
  }, [user]);

  const onStartWalkingClick = e => {
    const startTraining = async () => {
      const res = await apiService.trainings.startTraining();
      if (res.training) setTrainingData(res.training);
    };
    startTraining();
  };

  const onFinishWalkingClick = e => {
    const finishWalking = async () => {
      if (!trainingData) return;
      const endOfTrainingData = { ...trainingData };
      endOfTrainingData.endTime = new Date();
      const res = await apiService.trainings.finishTraining();
      setTrainingData();
      if (res.weeklyTrainings) setWeeklyTrainings(res.weeklyTrainings);
      setTrainingEndData(endOfTrainingData);
    };
    finishWalking();
  };

  useEffect(() => {
    if (!user || !trainingData) return;
    const updateCurrentTrainingData = () => {
      const clockTime = moment
        .utc(moment(new Date()).diff(moment(trainingData.startTime)))
        .format('HH:mm:ss');

      trainingClockRef.current.innerHTML = clockTime;
    };
    updateCurrentTrainingData();
    const interval = setInterval(updateCurrentTrainingData, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [user, trainingData]);

  useEffect(() => {
    if (!user || !weeklyTrainings) return;
    const { trainingTimeHoursWeeklyGoal } = user;

    const updateWeeklyStats = () => {
      const text = getWeeklyTrainingsTimeText(weeklyTrainings, trainingData);
      weeklyTimeSentenceRef.current.innerHTML = text;

      if (parseInt(trainingTimeHoursWeeklyGoal) > 0) {
        const trainingTimeHoursWeeklyGoalText = getTrainingTimeGoalText(
          weeklyTrainings,
          trainingData,
          trainingTimeHoursWeeklyGoal
        );
        weeklyTimeGoalRef.current.innerHTML = trainingTimeHoursWeeklyGoalText;
      }
    };
    updateWeeklyStats();

    const interval = setInterval(updateWeeklyStats, 10 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [user, weeklyTrainings, trainingData]);

  useEffect(() => {
    if (!user || !user.caloriesToHour || !weeklyTrainings) return;

    const updateWeeklyStats = () => {
      const text = getWeeklyCaloriesBurnedText(
        weeklyTrainings,
        trainingData,
        user.caloriesToHour
      );
      weeklyCaloriesBurned.current.innerHTML = text;
    };
    updateWeeklyStats();

    const interval = setInterval(updateWeeklyStats, 12 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [weeklyTrainings, trainingData, user]);

  const shouldShowTrainingEndData =
    !trainingData &&
    trainingEndData &&
    new Date().getTime() - new Date(trainingEndData.endTime).getTime() <
      HIDE_MESSAGES_TIME_MS - 1000;

  useEffect(() => {
    if (!trainingEndData) return;

    const timeout = setTimeout(() => {
      setUpdateVersion(_forceUpdateVersion + 1);
    }, HIDE_MESSAGES_TIME_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, [trainingEndData]);

  return (
    user && (
      <div>
        <div className="main-menu">
          <div className="main-menu-button" onClick={onStartWalkingClick}>
            <div className="main-menu-text">התחל&nbsp;הליכה</div>
          </div>
          <div className="main-menu-button" onClick={onFinishWalkingClick}>
            <div className="main-menu-text">סיים&nbsp;הליכה</div>
          </div>
        </div>

        <div id="main-wrapper">
          {trainingData && (
            <div className="training-data-container">
              <div className="training-clock-title">בהצלחה באימון!</div>
              <div className="training-clock" ref={trainingClockRef}></div>
            </div>
          )}
          {shouldShowTrainingEndData && (
            <div className="training-data-container">
              <div
                className="training-clock-title small-title"
                dangerouslySetInnerHTML={{
                  __html: getTrainingEndTitle(trainingEndData)
                }}
              ></div>
            </div>
          )}
          {weeklyTrainings && (
            <div className="goals-and-stats">
              <div className="goal-sentence" ref={weeklyTimeSentenceRef}></div>
              <div className="goal-sentence" ref={weeklyTimeGoalRef}></div>
              <div className="goal-sentence" ref={weeklyCaloriesBurned}></div>
            </div>
          )}
        </div>
      </div>
    )
  );
}
